import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BaseService } from './base.service';


@Injectable({ providedIn: 'root' })
export class GameService extends BaseService {
    constructor(protected override http: HttpClient) {
        super(http);
    }
    private apiUrl = environment.apiUrl + '/user';

    checkOnboard() {
        return this.get<{ status: boolean }>(this.apiUrl + '/check-onboard');
    }


}